import React from 'react';

class MainBanner extends React.Component {
    render(){
        return (
            <div className="page-title-area item-volunteer">
                <div className="container">
                    <h1>Volunteer at KCC 2025</h1>
                    <span>Are you ready to serve?</span>
                </div>
            </div>
        );
    }
}
 
export default MainBanner;