import lax from 'lax.js';
import React from 'react';
import LaxDiv from '../Shared/LaxDiv';

class EventSchedules extends React.Component {

  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render() {
    return (
      <section className="schedule-area schedule-style-three bg-image ptb-120">
        <div className="container">

          <div className="section-title">
            <span>Schedule</span>
            <h2>Conference Schedule</h2>
            <LaxDiv text="Schedule" dataPreset="driftLeft" />

            <div className="bar"></div>
            <p>Please note: This schedule may change.</p>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="single-schedule-item">
                <div className="schedule-date">
                  Day - 01
                  <span>Friday 2nd May 2025</span>
                </div>
                <div className="schedule-item-wrapper">
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Registration Opens</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>2:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Celebration Evening</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>7:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Note: Canteen will be available for Friday Dinner</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="single-schedule-item">
                <div className="schedule-date">
                  Day - 02
                  <span>Saturday 3rd May 2025</span>
                </div>
                <div className="schedule-item-wrapper">
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Canteen Opens</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>8:00 AM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Free time - time to catch up</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>9:00 AM - 12:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Prayer</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>1:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Bible Session 1</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>1:30 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Canteen</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>5:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Prayer</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>6:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Bible Session 2 & Celebration Evening</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>6:30 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="single-schedule-item">
                <div className="schedule-date">
                  Day - 03
                  <span>Sunday 4th May 2025</span>
                </div>
                <div className="schedule-item-wrapper">
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Canteen Opens</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>7:30 AM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Prayer</h3>

                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>9:00 AM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Bible Session 3</h3>

                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>9:30 AM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>KCC Members AGM</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>11:30 AM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Canteen</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>12:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Prayer & Fellowship Gathering - Missions Interlink</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>1:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Music & Art Workshops</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>2:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Leadership Gathering</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>4:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Canteen</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>5:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Prayer</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>6:00 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Bible Session 4 & Celebration Evening</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>6:30 PM
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="single-schedule-item">
                <div className="schedule-date">
                  Day - 04
                  <span>Monday 5th May 2025</span>
                </div>
                <div className="schedule-item-wrapper">
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Canteen Opens</h3>
                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>7:30 AM
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-content">
                    <div className="schedule-info">
                      <h3>Camping Groups to depart</h3>

                      <ul>
                        <li>
                          <i className="icofont-wall-clock"></i>10:00 AM
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
        </div>
        <div className="shape2 rotateme">
          <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
        </div>
        <div className="shape3 rotateme">
          <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
        </div>
        <div className="shape4">
          <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
        </div>
      </section >
    );
  }
}

export default EventSchedules;