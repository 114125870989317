import lax from 'lax.js';
import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import { Link } from 'react-router-dom';

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  items: 1,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>"
  ],
}

class Info extends React.Component {

  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render() {
    return (
      <>
        <section className="about-area ptb-120 bg-image">
          <div className="container">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <OwlCarousel
                  className="schedule-slides owl-carousel owl-theme"
                  {...options}
                >
                  <img src={require("../../assets/images/evenings_2.png")} className="about-img1" alt="Evenings 2" />
                  <img src={require("../../assets/images/evenings_3.png")} className="about-img1" alt="Evenings 3" />
                  <img src={require("../../assets/images/evenings_4.png")} className="about-img1" alt="Evenings 4" />
                  <img src={require("../../assets/images/evenings_5.png")} className="about-img1" alt="Evenings 5" />
                  <img src={require("../../assets/images/evenings_6.png")} className="about-img1" alt="Evenings 6" />
                  <img src={require("../../assets/images/evenings_7.png")} className="about-img1" alt="Evenings 7" />
                </OwlCarousel>
              </div>

              <div className="col-lg-6">
                <div className="about-content">
                  <span>Attending the Conference</span>
                  <h2><b>Celebration</b> Evenings</h2>

                  <p>As well as the usual Saturday and Sunday evenings on after the bible talks, we will once again have a time of celebration when community church groups can share Christian songs and other items.</p>

                  <p>In 2025, we will again have a Fellowship style worship on Friday Night.
                  For all these Evening sessions, we are hoping that Groups can do songs, dances, share bible stories, items, dramas on the Theme <strong>'Forgiveness'</strong>.</p>

                  <p>We normally have many items, so please register your item before 6pm on the day. Where possible bring original Christian music that you have written yourselves to share during this time. You are also welcome to share a drama, artwork or another short item (song/dance). Please note that on Saturday/Sunday, each group or person will be limited to 2 songs on one night only to enable as many groups as possible to participate.</p>
                  
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-area-three ptb-120 bg-image">
          <div className="container">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className="about-content">
                  <h2>Children and <b>Teenagers</b></h2>
                  <p>During the main session times Scripture Union will run the Children's Program. There will also be a separate program for teenagers happening during main sessions (run by volunteers from Maitland).</p>

                  <p>This year, as part of our intention to care for the children, we will also have a small team of adults who will look out for any child outside the program. If you are a parent, then we encourage you to say hello to this team and help them when needed.</p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="about-image">
                  <img
                    src={require("../../assets/images/teenages_youth.png")}
                    className="about-img1"
                    alt="Teenages and Youth"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-area-two ptb-120 bg-image">
          <div className="container">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className="about-image">
                  <img src={require("../../assets/images/camping.png")} className="about-img1" alt="Camping" />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="about-content">
                  <h2><b>Camping</b></h2>

                  <p>Basic camping at the Katherine Showgrounds is available from after 12 noon on Friday to 10am Monday morning. The cost of camping is no extra to the entry fee, however as we rely on donations to offer this, any donation towards camping is appreciated.</p>

                  <p>On arrival, please go to the front gate to find out where you can camp. Most camp sites do not have power, but camping areas have toilets and showers.</p>

                  <p>No fires will be allowed. However, hot water will be available for campers. Bring your own camping gear; tent, swag, tarp, blankets etc. </p>
                  
                  <p>For more information about camping, <Link to="/contact" >contact us</Link>.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Info;