import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
 
class Speaker extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
          <div className="container ptb-120">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <span>2025</span>
                  <h2>Who's Speaking</h2>
                  <div className="bar"></div>
                  <LaxDiv text="Speaker" dataPreset="driftRight" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="blog-details">
                  <p>Our theme for 2025 is <strong>'Forgiveness'</strong>. 
                    Our speakers this year are sisters Rachael Kendino and Wendy Willika, who have been coming to KCC for several decades. 
                    They are excited to have the opportunity to share on this year's theme.
                  </p>
                  <p>
                    Please bring your Bibles to the teaching times. A time of prayer will be held 30 minutes before and after each session. 
                  </p>
                  <p>  
                    Rachael and Wendy have provided the following greeting to the Christian community about KCC 2025:
                  </p>

                  <blockquote className="blockquote">
                    <p>Our names are Rachael Kendino and Wendy Willika. We are from Jawoyn Bolmo country - southern district of Kakadu. We have 3 generations after us (because Rachael and our sister has great grandchildren). Rachael is married to Max who lives in PNG. Since we became Christians we have enjoyed coming to. KCC - participating through dances, prayer groups, sharing and met a lot of friends and family. We love KCC because all the families come together and the Lord wants unity. We love that all the balanda (whitefella) and people from other places come to learn about the Lord as well.</p>
                  </blockquote>
                </div>
              </div>
              <div className="col-lg-6">
              <section className="speakers-area-two">
                <div className="elkevent-single-speakers">
                  <img src={require("../../assets/images/Rachael_Wendy_2025.jpg")} alt="Speaker 2025" />

                  <div className="speakers-content">
                      <h3><Link to="#">Rachael Kendino and Wendy Willika</Link></h3>
                      <span>Long term KCC attendees</span>
                  </div>
                </div>
              </section>
              </div>
            </div>
          </div>           
        );
    }
}
 
export default Speaker;